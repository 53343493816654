import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MainComponent } from "./theme/Layouts/main/main.component";
import { AuthGuard } from "./services/auth-guard/auth.guard";
const routes: Routes = [
  {
    path: "", pathMatch: "full", redirectTo: "slip-list"
  },
  {
    path: "login",
    loadChildren: () => import("./pages/login/login.module").then(m => m.LoginModule)
  },
  {
    path: "",
    component: MainComponent,
    children: [
      {
        path: "slip-list",
        loadChildren: () => import("./pages/slip-list/slip-list.module").then(m => m.SlipListModule),
        canActivate: [AuthGuard]
      },
      {
        path: "user-information-management",
        loadChildren: () => import("./pages/user-information-management/user-information-management.module").then(m => m.UserInformationManagementComponentModule),
        canActivate: [AuthGuard]
      },
      {
        path: "site-management",
        loadChildren: () => import("./pages/web-information-management/web-information-management.module").then(m => m.WebInformationManagementComponentModule),
        canActivate: [AuthGuard]
      },
      // {
      //   path: "slip-list/check-list/:id",
      //   loadChildren: () => import("./pages/check-list/check-list.module").then(m => m.CheckListModule),
      //   canActivate: [AuthGuard]
      // },
      {
        path: "slip-list/voucher-detail/:id",
        loadChildren: () => import("./pages/voucher-detail/voucher-detail.module").then(m => m.VoucherDetailModule),
        canActivate: [AuthGuard]
      },
      {
        path: "image-batch-registration",
        loadChildren: () => import("./pages/image-batch-registration/image-batch-registration.module").then(m => m.ImageBatchRegistrationModule),
        canActivate: [AuthGuard]
      },
      {
        path: "data-analysis",
        loadChildren: () => import("./pages/data-analysis/data-analysis.module").then(m => m.DataAnalysisModule),
        canActivate: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
