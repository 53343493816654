import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class DataService {
  private messageSource = new BehaviorSubject('default');
  currentMessage = this.messageSource.asObservable();

  private flagSource = new BehaviorSubject('Default message');
  currentFlag = this.flagSource.asObservable();

  private messageSession = new BehaviorSubject('');
  currentMessageSession = this.messageSession.asObservable();

  constructor() { }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }
  changeFlag(flag: string) {
    this.flagSource.next(flag);
  }
  changeMessSession(error: string) {
    this.messageSession.next(error);
  }
}
