import { Component, OnInit } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { faFile, faBars, faEllipsisV, faMap, faUserFriends, faChartBar } from "@fortawesome/free-solid-svg-icons";
import { slideInAnimation } from "../../../common/animations/screenAnimation";
import { getUserInfo } from "../../../common/functions-common/get-info";
import { AuthService } from "../../../services/auth-guard/auth.service";
import { DataService } from "../../../services/data/data.service";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.css"],
  animations: [
    slideInAnimation
  ]
})
export class MainComponent implements OnInit {
  // Icon variables
  faFile = faFile;
  faBars = faBars;
  faEllipsisV = faEllipsisV;
  faMap = faMap;
  faUserFriends = faUserFriends;
  faChartBar = faChartBar;
  title = "EarthBrain";
  showMyContainer = false;
  isShowMenuSetting = false;
  status = false;
  statusLink = false;
  roleId: number;
  userName:string;
  constructor(
    private authService: AuthService,
    private data: DataService,
  ) { }

  ngOnInit(): void {
    this.userName = getUserInfo()?.username; //get username
    this.roleId = getUserInfo().role_id;
  }

  toggleMenu() {
    this.status = !this.status;
    if (this.statusLink) {
      setTimeout(() => {
        this.statusLink = false;
      }, 230);
    } else {
      this.statusLink = true;
    }
  }

  openMenuSetting(): void {
    this.isShowMenuSetting = true;
  }

  onLogout(): void {
    this.data.changeMessSession("")
    this.authService.logout();
  }
}
