import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { environment } from "../../../environments/environment";
import { TOKEN, CURRENT_USER } from "../../common/constants/KeyLocalstorage";
import { LoginRequest } from "../../pages/login/login-request";
import { UserModel } from "./user-model";
import { VoucherRequest } from '../../pages/voucher-detail/voucher-detail-request';

@Injectable({ providedIn: 'root' })
export class AuthService {
  userModel!: UserModel;
  private currentUserSubject: BehaviorSubject<UserModel>;
  public currentUser: Observable<UserModel>;

  constructor(
    private http: HttpClient,
    private routerLink: Router
  ) {
    const getUserFromLocal = localStorage.getItem(TOKEN);
    this.currentUserSubject = new BehaviorSubject<UserModel>(JSON.parse(`${getUserFromLocal}`));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  login(credentials: LoginRequest) {
    return this.http.post<any>(`${environment.apiUrl}/login`, credentials)
      .pipe(map((user: any) => {
        if (user.token !== undefined) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem(TOKEN, JSON.stringify(user.token));
          localStorage.setItem(CURRENT_USER, JSON.stringify(user.userInfo));
          this.currentUserSubject.next(user.userInfo);
        }
        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(CURRENT_USER);
    this.currentUserSubject.next(null);
    this.routerLink.navigate(["/login"]);
  }

  voucherDetail(tickets: VoucherRequest) {
    return this.http.post<any>(`${environment.apiUrl}/ticket`, tickets);
  }
  getListVoucher(ticket: VoucherRequest) {
    return this.http.post<any>(`${environment.apiUrl}/ticketdata`, ticket);
  }
}
