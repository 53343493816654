<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<div class="navbar-eb shadow bg-eb">
    <button class="btn btn-link rounded-circle btn_toggleMenu" (click)="toggleMenu()"><fa-icon [icon]="faBars"></fa-icon></button>
    <span class="title_page">AI-OCRアプリ</span>
    <!-- get username -->
    <div>login user:&ensp;{{userName}}</div>
    <div>
        <ul>
            <li class="btnSetting">
                <fa-icon [icon]="faEllipsisV" class="fa-ellipsis-v"></fa-icon>
                <ul class="dropdownSetting">
                    <li><a (click)="onLogout()">Logout</a></li>
                </ul>
            </li>
        </ul>
    </div>
</div>
<div class="flex-container">
    <div class="sidebarMenu" [ngClass]="status ? 'darSideBar' : 'genisSideBar'">
        <ul class="navbar-nav">
            <div *ngIf="roleId === 2">
                <li class="nav-item">
                    <a class="nav-link color-custom" [routerLink]="['/slip-list']" routerLinkActive="active">
                        <fa-icon [icon]="faFile" style="width: 30px; margin-left: 2px;"></fa-icon>
                        <span [ngClass]="statusLink ? 'navLinkDar' : 'navLinkGenis animated fadeInRight'" style="margin-left: 18px">伝票リスト</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link color-custom" [routerLink]="['/site-management']" routerLinkActive="active">
                        <fa-icon [icon]="faMap" style="width: 30px"></fa-icon>
                        <span [ngClass]="statusLink ? 'navLinkDar' : 'navLinkGenis animated fadeInRight'" style="margin-left: 15px">現場管理</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link color-custom" [routerLink]="['/user-information-management']" routerLinkActive="active">
                        <fa-icon [icon]="faUserFriends" style="width: 30px"></fa-icon>
                        <span [ngClass]="statusLink ? 'navLinkDar' : 'navLinkGenis animated fadeInRight'" style="margin-left: 15px">ユーザー管理</span>
                    </a>
                </li>
            </div>
            <div *ngIf="roleId === 1">
                <li class="nav-item">
                    <a class="nav-link color-custom" [routerLink]="['/slip-list']" routerLinkActive="active">
                        <fa-icon [icon]="faFile" style="width: 30px"></fa-icon>
                        <span [ngClass]="statusLink ? 'navLinkDar' : 'navLinkGenis animated fadeInRight'" style="margin-left: 18px">伝票リスト</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link color-custom" [routerLink]="['/data-analysis']" routerLinkActive="active">
                        <!-- <fa-icon [icon]="faFile" style="width: 30px"></fa-icon> -->
                        <i class="material-icons white-color">equalizer</i>
                        <span [ngClass]="statusLink ? 'navLinkDar' : 'navLinkGenis animated fadeInRight'" style="margin-left: 12px">データ解析</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link color-custom" [routerLink]="['/site-management']" routerLinkActive="active">
                        <fa-icon [icon]="faMap" style="width: 30px"></fa-icon>
                        <span [ngClass]="statusLink ? 'navLinkDar' : 'navLinkGenis animated fadeInRight'" style="margin-left: 15px">現場管理</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link color-custom" [routerLink]="['/user-information-management']" routerLinkActive="active">
                        <fa-icon [icon]="faUserFriends" style="width: 30px"></fa-icon>
                        <span [ngClass]="statusLink ? 'navLinkDar' : 'navLinkGenis animated fadeInRight'" style="margin-left: 15px">ユーザー管理</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link color-custom" [routerLink]="['/image-batch-registration']" routerLinkActive="active">
                        <!-- <fa-icon [icon]="faUserFriends" style="width: 30px"></fa-icon> --><span style="padding-left: 20px;"></span>
                        <span [ngClass]="statusLink ? 'navLinkDar' : 'navLinkGenis animated fadeInRight'" style="margin-left: 15px">画像一括登録
                        </span>
                    </a>
                </li>
            </div>
        </ul>
    </div>
    <div id="maincontent">
        <router-outlet #outlet="outlet"></router-outlet>
    </div>
</div>
