import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MainComponent } from "./Layouts/main/main.component";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@NgModule({
    imports: [
      CommonModule,
      RouterModule,
      BrowserModule,
      BrowserAnimationsModule,
      FontAwesomeModule
    ],
    exports: [],
    declarations: [
      MainComponent,
    ],
    providers: [
    ],
})
export class ThemeLayoutModule { }
