import { CURRENT_USER } from "../constants/KeyLocalstorage"
import { TOKEN } from "../constants/KeyLocalstorage"

export const getUserInfo = () => {
  const userInfo = JSON.parse(localStorage.getItem(CURRENT_USER));
  return userInfo;
};
export const getToken = () => {
  const Token = JSON.parse(localStorage.getItem(TOKEN));
  return Token;
};
