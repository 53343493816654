import { HttpClientModule } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ThemeLayoutModule } from "./theme/theme-layout.module";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "./shared/shared.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { GlobalErrorHandle } from "./services/error-handle/GlobalErrorHandle.service";
@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ThemeLayoutModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ThemeLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    FontAwesomeModule

  ],
  providers: [{provide: ErrorHandler, useClass: GlobalErrorHandle}],
  bootstrap: [AppComponent]
})
export class AppModule { }
